import { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";

export default function Header() {
  const { pathname, hash, key } = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView(true);
          const topOfElement = window.pageYOffset + element.getBoundingClientRect().top - 130;
          window.scroll({ top: topOfElement, behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false);
  }, [setMobileMenu]);

  const handlePresentMobileMenu = useCallback(() => {
    // window.scrollTo(0, 0);
    let el = document.getElementById('#mobile-menu');
    console.log(el);
    // document.getElementById('#mobile-menu').scrollTo(0, 0);
    setMobileMenu(true);
  }, [setMobileMenu]);
  return (<>
    <Navbar onPresentMobileMenu={handlePresentMobileMenu} onCloseMobileMenu={handleDismissMobileMenu} mobileMenu={mobileMenu} />
  </>
  );
}
