import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Nav from "./components/Nav";
import MobileNavbar from "../MobileNavbar";
import StyledButton from "../Button";
import Logo from "../../assets/images/logo.svg"
interface NavbarProps {
  onPresentMobileMenu: () => void;
  onCloseMobileMenu: () => void;
  mobileMenu: boolean;

}

const Navbar: React.FC<NavbarProps> = ({ onPresentMobileMenu, onCloseMobileMenu, mobileMenu }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <NavbarFixedTop className={`${scrollPosition > 30 && 'bg-gardient'} ${mobileMenu && ''}`} >
      <MobileNavbar onDismiss={onCloseMobileMenu} visible={mobileMenu} />

      <NavbarInner>
        <NavLogo to="/" className={({ isActive, isPending }) => {
          return isActive ? "active" : isPending ? "pending" : "";
        }}>
          <img src={require("./../../assets/images/logo.svg").default} alt="" />
        </NavLogo>
        <NavbarLinks>
          <Nav />
          <StyledButton variant='white'>Open LF App</StyledButton>
        </NavbarLinks>


        <MobileNavbarIcon onClick={mobileMenu ? onCloseMobileMenu : onPresentMobileMenu}>
          |||
          <img src={`./assets/images/mobileNavIcon.png`} alt="" />
        </MobileNavbarIcon>
      </NavbarInner>
    </NavbarFixedTop>
  );
};

export default Navbar;

const NavbarFixedTop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 97;
  min-height: 68px;
  height:68px;
padding: 0px 80px;
background-color:#7F56D9;
  // &.bg-gardient {
  //   background: linear-gradient(180deg, #06020E 14.49%, rgba(25, 16, 41, 0) 99.34%);;
  // }
@media(max-width: 768px) {
  padding: 0px 20px;
}
`;

const NavbarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  @media(max-width:991px) {
    padding: 10px 0px;
  }
`;
const NavLogo = styled(NavLink)`
z-index:2;
 @media(max-width:991px) {
    img {
      width: 85%;
    }
  }
`;
const MobileNavbarIcon = styled.div`
  display: none;
  padding-top: 8px;
  color: white;
  transform:rotate(90deg);
  z-index: 97;
  cursor: pointer;
  @media(max-width:991px) {
    display: block;
  }
 
`;
const NavbarLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 160px;
  }
  @media(max-width:991px) {
    display: none;
  }



 
`;
