import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import NavigationTabArray from "../../../utils/navigationTab";
const Nav: React.FC = () => {



  return (
    <StyledNav>
      {NavigationTabArray.map((data, index) => (
        <StyledLink key={index} to={data.route} className={({ isActive }) => {
          return isActive ? "active" : "";
        }} >
          {data.name}
        </StyledLink>
      ))}


    </StyledNav>
  );
};

const StyledNav = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
  .disabled {
    pointer-events: none;
      opacity: 0.4;
  }
`;
const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-family: 'Inter';
  font-weight: 500; 
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color:#FFFFFF;
  padding : 0px 29px;
  position: relative;
  transition: all 0.3s ease;
  transform: translate3d(0px, 0px, 0px);
  box-sizing: border-box;
// z-index: 1000;
  &:hover, &.active {
    color: #FFFFFF !important;

  }

  :last-child {
    padding-right: 0px;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  @media (max-width: 1100px) {
    &.padding-high {
    padding: 0 1.3%;
    }
    &.padding-low {
      padding: 0 13px;
    }
  }
`;

export default Nav;
