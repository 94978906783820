import { lazy } from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import Footer from '../components/Footer'

const Banner = lazy(() => import("./Banner"));
const HowItWorks = lazy(() => import("./HowItWorks"));
const Services = lazy(() => import("./Services"));
const Blogs = lazy(() => import("./Blogs"));
const Collections = lazy(() => import("./Collections"));
const SocialMedia = lazy(() => import("./SocialMedia"));
const InnerBanner = lazy(() => import("./InnerBanner"));
const FAQ = lazy(() => import("./FAQ"));


const LandingPage = () => {
    return (
        <Wrapper>
            <img src={require("../assets/images/left_gradient.svg").default} className=" gradient left" alt="" />
            <img src={require("../assets/images/right_gradient.svg").default} className="gradient right" alt="" />
            <Header />
            <Banner />
            <HowItWorks />
            <Services />
            <Blogs />
            <Collections />
            <SocialMedia />
            <InnerBanner />
            <FAQ />
            <Footer />

        </Wrapper>
    )
}

const Wrapper = styled.div`
min-height:100vh;
overflow-x: hidden;
.gradient {
    position: absolute;
    z-index:1;
    &.left {
        left:0;
        top:0;
    }
    &.right{
        right:0;
        top:0;
    }

}

background: #7F56D9;
position:relative;
background-image:url('./assets/images/bannerBg.svg');
background-repeat:no-repeat;
background-size:cover;
background-position:center center;
`

export default LandingPage