
interface NavigationTab {
    name: string;
    route: string;
    disabled: boolean;
    diffColor?: boolean
}
const NavigationTabArray: NavigationTab[] = [
    {
        name: "Home",
        route: "/",
        disabled: false
    },
    {
        name: "How it Works",
        route: "#howitworks",
        disabled: false
    },
    {
        name: "Lend",
        route: "#lend",
        disabled: false
    },
    {
        name: "Borrow",
        route: "#borrow",
        disabled: false
    },
    {
        name: "Community",
        route: "#community",
        disabled: false
    },
    {
        name: "Blog",
        route: "#blog",
        disabled: false
    }
]

export default NavigationTabArray