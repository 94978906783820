import React from 'react'
import styled from 'styled-components'
import facebook from '../../assets/images/facebook.svg'
import linkedin from '../../assets/images/linkedin.svg'
import twittericon from '../../assets/images/twittericon.svg'
import Logo from '../../assets/images/logo.svg'

const Footer = () => {
    return (
        <StyledFooter>
            <div className="row">
                <div className="col-md-4">
                    <img src={Logo} alt="" />
                    <p>Lendup .Finance facilitates the connection between NFT holders and liquidity
                        providers using cutting-edge smart contract technology, ensuring a seamless
                        and transparent lending experience. </p>
                    <div className="social-icons">
                        <img src={facebook} alt="" />
                        <img src={linkedin} alt="" />
                        <img src={twittericon} alt="" />

                    </div>

                </div>
                <div className="col-md-3 text-right">
                    <ul>
                        <li className="title">Menu Pages</li>
                        <li>Home</li>
                        <li>About</li>
                        <li>NFT</li>
                        <li>Road map</li>
                        <li>Blog</li>
                        <li>Contact</li>

                    </ul>
                </div>
                <div className="col-md-3">
                    <ul>
                        <li className="title">Company</li>
                        <li>Help & Support</li>
                        <li>Terms & Conditions</li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
                <div className="col-md-2">
                    <ul>
                        <li className="title">Resource</li>
                        <li>Partner</li>
                        <li>Blog</li>
                        <li>Newsletter</li>
                    </ul>
                </div>
            </div>
            <p className="copyright">Copyright @ Lendup.Finance 2023 All right reserved</p>

        </StyledFooter>
    )
}

const StyledFooter = styled.div`
padding:80px;
overflow:hidden;
img {
    margin-bottom:24px;
}
ul {
    li{
        &.title {
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            letter-spacing: -0.02em;
            color: #FFFFFF;

        }
        list-style: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom:20px;
        

    }
}
.social-icons {
    gap:20px;
    display:flex;
}
p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    color: #FFFFFF;
}
@media (max-width: 820px) { 
    padding:80px 20px;
    ul {
        padding-left:0;
    }

}
@media (max-width: 768px) { 
    padding:80px 20px;
    ul {
        padding-left:0;
    }

}
`

export default Footer