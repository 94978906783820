import { Suspense } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import LandingPage from './views/LandingPage';
import "./fonts/Poppins/style.css";
import "./fonts/Inter/inter.css";
import "./fonts/SpaceGrotesk/style.css";

import {
  BrowserRouter,

} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className="spinner-border" role="status">
      </div>}>
        <LandingPage />
      </Suspense>

    </BrowserRouter>
  );
}

export default App;
