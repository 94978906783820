import React from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import NavigationTabArray from "../../utils/navigationTab";
// import DiscordIcon from "components/Svg/Icons/Discord";
// import TwitterIcon from "components/Svg/Icons/Twitter";
// import InstagramIcon from "components/Svg/Icons/Instagram";
// import { Button } from "components/Button";
// import { useHistory } from "react-router-dom";
// import { StaticRoutes } from "utils/StaticRoutes";
import useViewport from "../../hooks/useViewPort";
import StyledButton from "../Button";
interface MobileMenuProps {
  onDismiss: () => void;
  visible?: boolean;
}

const MobileNavbar: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  const { width } = useViewport();
  // const history = useHistory();
  if (width > 852) {
    visible = false;
    onDismiss();
  }
  if (visible) {
    return (
      <StyledMobileMenuWrapper onClick={onDismiss} id="mobile-menu">
        <StyledBackdrop />

        <StyledMobileMenu>
          <StyledLogoAndIcon></StyledLogoAndIcon>

          {NavigationTabArray.map((data, index) => (
            <>
              <StyledLink key={index} to={data.route} className={({ isActive }) => {
                return isActive ? "active" : "";
              }} >
                {data.name}
              </StyledLink>
            </>
          ))}
          <StyledButton variant='white'>Open LF App</StyledButton>

        </StyledMobileMenu>
      </StyledMobileMenuWrapper>
    );
  }
  return null;
};

const StyledMobileMenuWrapper = styled.div`
  position: absolute;
  top: 67px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #7F56D9;
  height: 100vh;
  // margin-left: 18px;
  // margin-right: 18px;
  // min-height:100vh;
  // max-height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
  @media(max-width:768px) {
    top:65px;
  }
`;
const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10%;
`;
const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%);
  }
`;
const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  font-weight: bold;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 36px;
  color: #ff8c03;
  padding: 20px 20px 40px 30px;
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .social-icons {
    margin-top: 20px;
    svg {
      cursor: pointer;
      margin-right: 28px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
const StyledLogoAndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

const StyledLink = styled(NavLink)`
  padding-top: 20px;
  text-transform: capitalize;
  font-family: "Montserrat";
  font-style: normal;
  letter-spacing: 0.01em;

  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  text-decoration: none;
  text-transform: capitalize;

  color: #ffffff;

  &.active {
     color: #FFFFFF !important;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    text-decoration: none;
     color: #FFFFFF !important;
  }

 
`;
export default MobileNavbar;
