import styled from 'styled-components'
import { variant } from 'styled-system'
type Variants = "white" | "transparent" | "purple"
interface IButtonProps {
    fullWidth?: boolean
    variant?: Variants
}
const StyledButton = styled.button<IButtonProps>(
    {
        appearance: 'none',
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        padding: '12px 20px',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '8px',
        zIndex: '3',
        minWidth: 'max-content',


    },

    variant({
        variants: {
            transparent: {
                color: '#FFFFFF',
                bg: 'transparent',
                border: '1px solid #FFFFFF'
            },
            white: {
                color: '#7F56D9',
                bg: '#FFFFFF',
                border: '1px solid #FFFFFF'
            },
            purple: {
                color: '#FFFFFF',
                bg: '#7F56D9',
                border: '1px solid #7F56D9'
            },
        }
    })
)
export default StyledButton